import React, { useEffect, useState } from 'react';
import Carousel from '../../../components/Carousel';
import ContentContainer from '../../../components/ContentContainer';
import Typography from '../../../components/Typography';
import { colors, styles } from '../../../theme';
import Breadcrumb from '../../common/navigation/Breadcrumb';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { properties } from '../../../theme/';
import styled from '@emotion/styled';
import _ from 'lodash';
import PurchaseButton from './PurchaseButton';
import TitleContainer from './TitleContainer';
import SellerContainer from './SellerContainer';
import Expanders from './Expanders';
import useItem from './useItem';
import Divider from './Divider';
import IconTitle from './IconTitle';
import DescriptiveTypography from './DescriptiveTypography';
import SpecificationContainer from './SpecificationContainer';
import { partner, layoutSettings, useIsSmallScreen } from './utils';
import { useIsMobile } from '../../../utils/useWindowDimensions';
import { getItemImgFromUrl } from '../../../api/items/utils';

type StyleProps = {
  isMobile: boolean;
  isSmallScreen: boolean;
};
const StyledContentContainer = styled(ContentContainer)<StyleProps>(({ isMobile, isSmallScreen }) => `
  .sub-container {
    display: flex;
    justify-content: space-between;
    margin-top: 66px;
    .left {
    }
  }
  .sustainability-options {
    flex-wrap: wrap;
    display: flex;
    margin-top: 8px;
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 98px;
  }
  ${isSmallScreen ? `
    .sub-container {
      flex-direction: column;
      align-items: center;
    }
    .right {
      align-items: center;
      margin-top: 30px;
      margin-left: 0px;
      width: 100%;
    }
  ` : ''}
  ${styles.itemDetail({ isSmallScreen, isMobile })}
`);

export default function ItemDetail() {
  /* HOOKS */
  const { t } = useTranslation();
  const { id } = useParams();
  const isSmallScreen = useIsSmallScreen();
  const isMobile = useIsMobile();

  useEffect(() => document.getElementById('layout')?.scrollTo({ top: 0 }), []);

  // Guard
  if (!id) return null;

  /* QUERIES */
  const {
    item,
    sustainabilityOptions,
  } = useItem();

  /* ITEM VARS */
  const images = [
    ...(item?.partnerimage_set ?? []).map(i => ({ ...i, partner: true })),
    ...(item?.itemimage_set ?? []).map(i => ({ ...i, partner: false, url: i.item_image })),
  ];

  /* STATES */
  const [selectedImg, setSelectedImg] = useState(0);

  const prepath = useLocation().pathname.split('/')[1];

  return (
    <StyledContentContainer isMobile={isMobile} isSmallScreen={isSmallScreen} className='item-detail'>
      <Breadcrumb
        title={item?.title ?? ''}
        middleRoutes={[
          {
            title: t('nav.' + prepath),
            path: '/' + prepath
          }
        ]}
      />
      <div className='sub-container'>
        <div className='left'>
          <Carousel<{ url: string; partner: boolean; }>
            selectedIndex={selectedImg}
            onSelect={(i) => setSelectedImg(i)}
            images={images}
            thumbSrc={img => getItemImgFromUrl(img.url, properties.partnerImageArgs.carouselThumb, img.partner) ?? ''}
            imgSrc={img => getItemImgFromUrl(img.url, properties.partnerImageArgs.carouselImg, img.partner) ?? ''}
            bigPicSrc={img => getItemImgFromUrl(img.url, properties.partnerImageArgs.carouselBigPic, img.partner) ?? ''}
            selector={layoutSettings.carouselDefaultSelector}
          />
          {partner == 'outnorth' && !isSmallScreen && <Expanders />}
        </div>
        <div className='right'>
          <TitleContainer />
          {partner == 'stjarnurmakarna' && (
            <Attributes
              attributes={[item?.raw_data?.usp1, item?.raw_data?.usp2]}
            />
          )}
          {partner == 'houdini' && (<>
            <DescriptiveTypography variant='detail-body1'>{item?.description}</DescriptiveTypography>
            {sustainabilityOptions.length > 0 && (
              <div className='sustainability-options'>
                {sustainabilityOptions.map(({ title, icon }, index) => (
                  <IconTitle
                    key={'sbadge'+title+index}
                    title={t(title)}
                    icon={icon}
                  />
                ))}
              </div>
            )}
            <Divider />
            <DescriptiveTypography variant='general-subtitle2'>{item?.color}</DescriptiveTypography>
            <DescriptiveTypography variant='general-subtitle2'>{t('item.size')}: {item?.size}</DescriptiveTypography>
            <DescriptiveTypography variant='general-subtitle2'>{images[selectedImg]?.description}</DescriptiveTypography>
          </>)}
          {partner == 'outnorth' && (<>
            <ul className='item-meta'>
              <li><Typography variant='detail-body1'>{t('item.color')}: {item?.color}</Typography></li>
              <li><Typography variant='detail-body1'>{t('item.size')}: {item?.size}</Typography></li>
              <li><Typography variant='detail-body1'>{t('item.condition.label')}: {t(`item.condition.${item?.listing.condition}`)}</Typography></li>
              {!!item?.properties?.["Material"] && <li><Typography variant='detail-body1'>{t('item.material')}: {item.properties["Material"]}</Typography></li>}
            </ul>
            <PurchaseButton />
          </>)}
          <SellerContainer />
          {partner == 'houdini' && <Expanders />}
        </div>
      </div>
      {partner == 'stjarnurmakarna' && <SpecificationContainer />}
      {partner == 'outnorth' && isSmallScreen && <Expanders />}
    </StyledContentContainer>
  );
}

const Attributes = ({ attributes }: { attributes?: (string|undefined)[]; }) => {
  if (!((attributes ?? []).length > 0)) return null;
  return (
    <AttributesContainer>
      {attributes!.filter(a => !!a).map(attribute => (
        <IconTitle
          key={`attribute-${attribute}`}
          title={attribute!}
          icon="check"
          isMuiIcon
        />
      ))}
    </AttributesContainer>
  );
}
const AttributesContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  margin-top: 8px;
  .MuiIcon-root {
    color: ${colors.primaryAccent};
  }
`;
