import colors from './colors.json';
import { Styles } from './types';

const styles: Styles = {
  "layout": ({ isMobile }) => `
    .search-drawer > .MuiPaper-root {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      ${isMobile ? 'margin-top: 33px;' : ''}
      .search-input {
        flex: 1;
        background: none;
      }
      .close-button {
        margin-right: ${isMobile ? '18px' : '80px'} !important;
        background: none;
      }
    }
  `,
  "logo": `
    &.top-logo-container > .recrql {
      width: 64px;
    }
  `,
  "nav": {
    "superContainer": ``,
    "desktopMainContainer": ``,
    "flexContainer": ``,
    "navLogo": ``,
    "linksContainer": ``,
  },
  "button": ``,
  "breadcrumbNavLink": ``,
  "itemsView": {
    "title": ``,
    "topContainer": ``
  },
  "carousel": ``,
  "itemFiltersDesktopView": ``,
  "dropdownIconColor": colors.primaryAccent,
  "itemDetail": () => ``,
  "divider": ``,
}

export default styles;
