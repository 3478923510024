import {
  FetchNextPageOptions,
  FetchPreviousPageOptions,
  InfiniteQueryObserverResult,
  QueryFunction,
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  useQuery as useQueryRQ,
  UseQueryOptions,
  UseQueryResult,
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
  InfiniteData,
} from "react-query";
import { fetchPaginationUrl } from "./endpoints";
import { Pagination, PaginationFn, PaginationParams } from "./types";
import * as localStorage from '../../utils/localStorage';

export interface PaginationReturn<TResult, TFacets, TData> {
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
  fetchPreviousPage: (options?: FetchPreviousPageOptions) => Promise<InfiniteQueryObserverResult<TData, string>>;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult<TData, string>>;
  data: TResult[];
  facets?: TFacets;
  count: number;
  isLoading: boolean;
  isFetched: boolean;
  refetch: (options?: (RefetchOptions & RefetchQueryFilters<TData>)) => Promise<QueryObserverResult<InfiniteData<TData>, string>>;
}

export function usePagination<
  TResult,
  TFacets = void,
  TData extends Pagination<TResult, TFacets> = Pagination<TResult, TFacets>
>(
  queryKey: QueryKey,
  params: PaginationParams,
  queryFn: PaginationFn<TData, TResult, TFacets>,
  authenticate: boolean
): PaginationReturn<TResult, TFacets, TData> {
  let enabled;
  if (authenticate) {
    enabled = localStorage.getItem('isAuthenticated') === true;
  }
  const query = useInfiniteQuery<TData, string>(queryKey, ({ pageParam }: QueryFunctionContext<QueryKey, string|undefined>) => {
    if (!pageParam) {
      return queryFn(params);
    } else {
      return fetchPaginationUrl<TData, TResult, TFacets>(pageParam, authenticate);
    }
  }, {
    getPreviousPageParam: p => p.previous,
    getNextPageParam: p => p.next,
    enabled,
  })
  const data: TResult[] = query.data?.pages.map(i => i.results).reduce((t, c) => t.concat(c) as [TResult]) ?? []
  const count = query.data?.pages?.[0]?.count ?? 0;
  return {
    refetch: query.refetch,
    hasPreviousPage: query.hasPreviousPage ?? false,
    hasNextPage: query.hasNextPage ?? false,
    fetchPreviousPage: query.fetchPreviousPage,
    fetchNextPage: query.fetchNextPage,
    data,
    facets: query.data?.pages?.[0]?.facets,
    count,
    isLoading: query.isLoading,
    isFetched: query.isFetched,
  };
}

type QueryOptions<TQueryFnData, TError, TData, TQueryKey extends QueryKey = QueryKey> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'> & {
  authenticate?: boolean;
}
export function useQuery<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: QueryOptions<TQueryFnData, TError, TData, TQueryKey>): UseQueryResult<TData, TError> {
  if (options?.authenticate != null) {
    options.enabled = (options.enabled ?? true) && localStorage.getItem('isAuthenticated') === true;
  }
  return useQueryRQ(queryKey, queryFn, options);
}
