import React from 'react';
import styled from 'styled-components';
import Icon from '../../../components/Icon';
import SvgImg from '../../../components/SvgImg';
import Typography from '../../../components/Typography';
import { useIsSmallScreen } from './utils';

const StyledIconTitle = styled.div<{ isSmallScreen: boolean; }>(({ isSmallScreen }) => `
  margin: ${isSmallScreen ? '13px 13px 0 13px' : '13px 13px 0 0'};
  align-items: center;
  display: flex;
`);

type IconTitleProps = {
  title: string;
  icon: string;
  isMuiIcon?: boolean;
} & Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'>;

export default function IconTitle({ title, icon, isMuiIcon, ...props }: IconTitleProps) {
  const isSmallScreen = useIsSmallScreen();
  return (
    <StyledIconTitle isSmallScreen={isSmallScreen} {...props}>
      {isMuiIcon ? (
        <Icon name={icon} />
      ) : (
        <SvgImg src={`assets/icons/${icon}`} width={30} />
      )}
      <Typography variant='general-subtitle1' style={{ marginLeft: 6 }}>{title}</Typography>
    </StyledIconTitle>
  );
}
