import { useContext, useEffect, useState } from "react";
import { Item } from "../../api/items/types";
import { getPaymentIntent, getSetupIntent } from "../../api/payments/endpoints";
import AuthContext from "../../contexts/AuthContext";
import { useSearchParams } from "../../utils/useSearchParams";
import { handleError } from "./utils";

export default function useClientSecret(item_id: Item['id'], onError: (error: string) => void) {
  const [{ isAuthenticated }] = useContext(AuthContext);
  const [clientSecret, setClientSecret] = useState<string>();
  const [{ payment_intent_client_secret, setup_intent_client_secret }] = useSearchParams();
  useEffect(() => {
    if (payment_intent_client_secret) {
      setClientSecret(payment_intent_client_secret);
      return;
    }
    else if (setup_intent_client_secret) {
      setClientSecret(setup_intent_client_secret);
      return;
    }
    if (isAuthenticated === true) {
      console.debug('Have auth, calling setup intent')
      getSetupIntent().then(([res, err]) => {
        if (err) throw err
        if (!res?.client_secret) throw 'No client secret received from setup intent: ' + res;
        setClientSecret(res?.client_secret)
      }).catch(error => {
        onError(handleError(error));
      })
    } else {
      console.debug('No auth, calling payment intent')
      getPaymentIntent(item_id).then(([res, err]) => {
        if (err) throw err
        if (!res?.client_secret) throw 'No client secret received from payment intent: ' + res;
        setClientSecret(res.client_secret);
      }).catch(err => {
        onError(handleError(err));
      })
    }
  }, [isAuthenticated])
  return clientSecret;
}
