import React from 'react';
import Button from '../../components/Button';
import Hyperlink from '../../components/Hyperlink';
import Typography from '../../components/Typography';
import CopyContainer from '../CopyContainer';

export default function SupportView() {
  return (
    <CopyContainer
      title='Support'
      subtitles={[]}
    >
      <Typography variant='general-body1'>
        Om du har några frågor, synpunkter eller funderingar hör gärna av dig till&nbsp;
        <Hyperlink href='mailto:hello@reCRQL.com' text='hello@reCRQL.com' /> så återkommer vi så snart som möjligt.
        reCRQL är under uppbyggnad och synpunkter på förbättringar av tjänsten tas 
        gladeligen emot. Tillsammans gör vi skillnad på riktigt!
      </Typography>
    </CopyContainer>
  );
}
