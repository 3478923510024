export default {
  "general-h1": `
    font-family: Neo Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.5px;
  `,
  "general-h2": `
    font-family: Neo Sans Pro;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.5px;
  `,
  "general-h3": `
    font-family: Neo Sans Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.5px;
    text-align: left;
  `,
  "general-body1": `
    font-family: goudy-old-style;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.5px;
    text-align: left;
  `,
  "general-body2": `
    font-family: goudy-old-style;
    font-size: 18px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.5px;
  `,
  "general-button1": `
    font-family: Neo Sans Std;
    font-size: 14px;
    font-weight: 400;
  `,
  "general-button2": `
    font-family: Neo Sans Std;
    font-size: 14px;
    font-weight: lighter;
  `,
  "general-link": `
    font-family: goudy-old-style;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
  `,
  "general-subtitle1": `
    font-family: Neo Sans Pro;
    font-weight: 400;
    font-size: 13px;
    line-height: 15.6px;
    letter-spacing: -0.5px;
  `,
  "general-subtitle2": `
    font-family: Neo Sans Pro;
    font-weight: 300;
    font-size: 13px;
    line-height: 15.6px;
    letter-spacing: -0.5px;
  `,
  "detail-h1": `
    font-family: Neo Sans Std;
    font-size: 14px;
    font-weight: lighter;
  `,
  "detail-body1": `
    font-family: goudy-old-style;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
    letter-spacing: -0.5px;
  `,
  "detail-body2": `
    font-family: Neo Sans Pro;
    font-weight: 400;
    font-size: 13px;
    line-height: 15.6px;
    letter-spacing: -0.5px;
  `,
  "detail-name": `
    font-family: Neo Sans Pro;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.5px;
  `,
  "detail-brand": `
    font-family: goudy-old-style;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
    letter-spacing: -0.5px;
  `,
  "detail-price": `
    font-family: Neo Sans Pro;
    font-weight: 400;
    font-size: 13px;
    line-height: 15.6px;
    letter-spacing: -0.5px;
  `,
  "detail-ogprice": `
    font-family: Neo Sans Pro;
    font-weight: 300;
    font-size: 13px;
    line-height: 15.6px;
    letter-spacing: -0.5px;
    text-decoration: line-through;
  `,
  "detail-link": ``,
  "detail-condition": ``,
  "nav-body1": `
    font-family: goudy-old-style;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
    letter-spacing: -0.5px;
  `,
  "nav-subtitle1": `
    font-family: Neo Sans Pro;
    font-weight: 400;
    font-size: 13px;
    line-height: 15.6px;
    letter-spacing: -0.5px;
  `,
  "nav-h1": `
    font-family: Neo Sans Pro;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.5px;
  `,
  "nav-button1": `
    font-family: Neo Sans Std;
    font-size: 14px;
    font-weight: 400;
  `,
  "nav-button2": `
    font-family: Neo Sans Std;
    font-size: 14px;
    font-weight: lighter;
  `,
  "nav-search": `
    font-family: Neo Sans Std;
    font-size: 14px;
    font-weight: 400;
  `,
  Body2: `
    font-family: goudy-old-style;
    font-size: 14px;
  `,
  "listing-price1": `
    font-family: Neo Sans Pro;
    font-weight: 400;
    font-size: 13px;
    line-height: 15.6px;
    letter-spacing: -0.5px;
  `,
  "listing-price2": `
    font-family: Neo Sans Pro;
    font-weight: 400;
    font-size: 13px;
    line-height: 15.6px;
    letter-spacing: -0.5px;
    text-decoration: line-through;
  `,
  "listing-title": `
    font-family: Neo Sans Std;
    font-size: 16px;
    font-weight: 400;
  `,
  "listing-subtitle": `
    font-family: Neo Sans Std;
    font-size: 14px;
    font-weight: 400;
  `,
  "listing-condition": ``,
  "drawer-title": `
    font-family: Neo Sans Pro;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.5px;
  `,
}
